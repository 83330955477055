import {
    AppstoreOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    ContainerOutlined,
    HomeOutlined,
    MailOutlined,
    MenuOutlined,
    SyncOutlined,
    WechatWorkOutlined,
    SendOutlined,
    CopyTwoTone
} from '@ant-design/icons';
import { Button, Collapse, Layout, Menu, message, Tag, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import remarkGfm from 'remark-gfm';
import './Files.css';
const { Sider, Content } = Layout;
const { Panel } = Collapse;

const homeItems = [
    { label: 'Home', key: 'home', icon: <HomeOutlined /> },
    { label: 'Chat', key: 'chat', icon: <WechatWorkOutlined /> }
];

const recordMenu = [
    { label: 'Record', key: 'record', icon: <HomeOutlined /> },
    { label: 'File', key: 'file', icon: <WechatWorkOutlined /> }
];

function Files() {

    const [inputValue, setInputValue] = useState('');
    const textareaRef = useRef(null);
    const chatContainerRef = useRef(null);
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false);  // State to track loading


    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleSend = () => {
        if (inputValue.trim()) {
            setMessages((prev) => [...prev, { role: 'user', content: inputValue }]);
            setLoading(true);  // Set loading to true when sending a message

            axios.post(`${process.env.REACT_APP_PORTURL}/chat`, { recordId: selectedRecord?.id, query: inputValue })
                .then(response => {
                    setMessages((prev) => [...prev, { role: 'assistant', content: response.data }]);
                })
                .catch(error => {
                    console.error('Error sending message:', error);
                }).finally(() => {
                    setLoading(false);  // Set loading to false after receiving the response
                })
            setInputValue(''); // Clear the input after sending
        }
    };


    const handleChange = (e) => {
        setInputValue(e.target.value);
        autoResizeTextarea();
    };

    const autoResizeTextarea = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto'; // Reset the height to auto to shrink if needed
            textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
            const height = parseInt(textarea.style.height.replace('px', ''))
            if (height > 150) {
                textarea.style.scrollbarWidth = 'auto'
                textarea.style.msOverflowStyle = 'auto'
            } else {
                textarea.style.scrollbarWidth = 'none'
                textarea.style.msOverflowStyle = 'none'
            }
        }
    };

    useEffect(() => {
        autoResizeTextarea(); // Adjust the height when the component mounts or input value changes
    }, [inputValue]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);


    const { id } = useParams();
    const [collapsed, setCollapsed] = useState(false);
    const [pages, setPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState({});
    const [isZoomedIn, setIsZoomedIn] = useState(false);
    const transformComponentRef = useRef(null);
    const [currentMenu, setCurrentMenu] = useState('content');
    const [currentSection, setCurrentSection] = useState('home');
    const [recordSection, setRecordSection] = useState('file');
    const [records, setRecords] = useState([])
    const [selectedRecord, setSelectedRecord] = useState({})
    const [messageApi, contextHolder] = message.useMessage();
    const items = [
        { label: 'Content', key: 'content', icon: <MailOutlined /> },
        { label: 'Task Result', key: 'task', icon: <AppstoreOutlined />, disabled: recordSection === 'file' },
        { label: 'Summary', key: 'summary', icon: <ContainerOutlined /> }
    ];

    const toggleSidebar = () => setCollapsed(!collapsed);

    useEffect(() => {
        getFile();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchContentData(currentMenu);
    }, [currentMenu]);

    const getFile = () => {
        axios.get(`${process.env.REACT_APP_PORTURL}/file-upload/${id}`)
            .then(response => {
                console.log(response.data);
                setPages(response.data.FilePages);
                setSelectedPage(response.data.FilePages[0]);
            })
            .catch(error => console.error('Failed to fetch file:', error));
    };

    const fetchContentData = menuKey => {
    };

    const handlePageChange = page => {
        setSelectedPage(page);
        const imgContainer = document.querySelector('.image-container');
        if (imgContainer) imgContainer.scrollTop = 0;
        setIsZoomedIn(false);
        if (transformComponentRef.current) {
            transformComponentRef.current.resetTransform();
        }
    };

    const handleMenuClick = e => setCurrentMenu(e.key);

    const handleSectionClick = e => setCurrentSection(e.key);
    const handlerecordClick = (e) => {
        setRecordSection(e.key)
        if (e.key === 'file') {
            setCurrentMenu('content')
            setSelectedRecord(null)
            setSelectedPage(pages[0])
        }
        if (e.key === 'record') {
            getRecordForFile(id)
            setSelectedPage(null)
            setSelectedRecord(records[0])
        }
    }

    const getRecordForFile = (fileId) => {
        axios.get(`${process.env.REACT_APP_PORTURL}/file-upload/record/${fileId}`)
            .then(response => {
                console.log(response.data);
                setRecords(response.data)
                setSelectedRecord(response.data[0])
                console.log(response.data[0]);

            })
            .catch(error => console.error('Failed to fetch file:', error));
    }

    const handleRecordChange = (record) => {
        setSelectedRecord(record)
        const contentContainer = document.querySelector('.content-item-display');
        if (contentContainer) contentContainer.scrollTop = 0;
        const imgContainer = document.querySelector('.image-wrapper')
        if (imgContainer) imgContainer.scrollTop = 0;
    }

    const handleCopyText = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                success('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    const success = (content) => {
        messageApi.open({
            type: 'info',
            content,
            duration: 3,
        });
    };

    return (
        <Layout style={{ height: '100%' }}>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                width="20%"
                style={{ backgroundColor: '#f0f0f0' }}
                collapsedWidth="0"
            >
                {!collapsed && (
                    <div className="sidebar-content">
                        <Button
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={toggleSidebar}
                            className="close-button"
                            style={{ position: 'absolute', top: '0px', right: '0px' }}
                        />
                        <div className="page-list">
                            <Menu style={{ maxWidth: "calc(100% - 20px)" }} onClick={handlerecordClick} selectedKeys={[recordSection]} mode="horizontal" items={recordMenu} />
                            <div className='item-list'>
                                {recordSection === 'file' && pages.map(m => (
                                    <div
                                        onClick={() => handlePageChange(m)}
                                        key={m.id}
                                        className={m.id === selectedPage.id ? "page-box-active" : "page-box"}
                                    >
                                        Page: {m.pageNumber}
                                        {m.status === 'COMPLETED' ?
                                            <Tooltip title="Completed">
                                                <Tag icon={<CheckCircleOutlined />} color='success'></Tag>
                                            </Tooltip> :
                                            m.status === 'IN_REVIEW' ?
                                                <Tooltip title="In Review">
                                                    <Tag icon={<ClockCircleOutlined />} color='orange'></Tag>
                                                </Tooltip> :
                                                m.status === 'IN_PROGRESS' ?
                                                    <Tooltip title="In Progress">
                                                        <Tag icon={<SyncOutlined spin />} color='blue'></Tag>
                                                    </Tooltip> :
                                                    <Tooltip title="Failed">
                                                        <Tag icon={<CloseCircleOutlined />} color='red'></Tag>
                                                    </Tooltip>
                                        }
                                    </div>
                                ))}
                                {recordSection === 'record' && records.map((m, i) => (
                                    <div
                                        onClick={() => handleRecordChange(m)}
                                        key={m.id}
                                        className=
                                        {m.id === selectedRecord?.id ? "recordFileShow-active" : "recordFileShow"}
                                    >
                                        <div className='fileClass'>
                                            <span> <strong>DOS: {m.dateOfService}</strong> </span>
                                        </div>
                                        <div>
                                            {m.FilePages.map(m => (
                                                <div
                                                    key={m.id}
                                                    className="page-box"
                                                    style={{
                                                        marginBottom: "5px",
                                                        cursor: 'auto'
                                                    }}
                                                >
                                                    Page: {m.pageNumber}
                                                    {m.status === 'COMPLETED' ?
                                                        <Tooltip title="Completed">
                                                            <Tag icon={<CheckCircleOutlined />} color='success'></Tag>
                                                        </Tooltip> :
                                                        m.status === 'IN_REVIEW' ?
                                                            <Tooltip title="In Review">
                                                                <Tag icon={<ClockCircleOutlined />} color='orange'></Tag>
                                                            </Tooltip> :
                                                            m.status === 'IN_PROGRESS' ?
                                                                <Tooltip title="In Progress">
                                                                    <Tag icon={<SyncOutlined spin />} color='blue'></Tag>
                                                                </Tooltip> :
                                                                <Tooltip title="Failed">
                                                                    <Tag icon={<CloseCircleOutlined />} color='red'></Tag>
                                                                </Tooltip>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                                {recordSection === 'record' && records.length === 0 && <div>Record in progress</div>}
                            </div>
                        </div>
                    </div>
                )}
                {collapsed && (
                    <Button
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={toggleSidebar}
                        className="toggle-button"
                        style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: '#A24EFF' }}
                    />
                )}
            </Sider>
            <Layout>
                <Content style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                    <div className="content-wrapper">
                        {recordSection === 'file' &&
                            <div className="image-container" onDoubleClick={() => setIsZoomedIn(!isZoomedIn)}>
                                <TransformWrapper
                                    ref={transformComponentRef}
                                    doubleClick={{ mode: isZoomedIn ? 'zoomOut' : 'zoomIn', step: 1 }} // Double-click to zoom in
                                    wheel={{ disabled: true }} // Disable zoom on wheel scroll
                                >
                                    <TransformComponent>
                                        <img
                                            src={`${process.env.REACT_APP_ROOTURL}/${selectedPage?.path}`}
                                            alt="Document"
                                            className="full-screen-image"
                                        />
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        }
                        {recordSection === 'record' &&
                            <div className='image-wrapper'>
                                {selectedRecord?.FilePages?.map(m => (
                                    <div style={{ marginBottom: '20px', border: '1px solid black' }} onDoubleClick={() => setIsZoomedIn(!isZoomedIn)}>
                                        <TransformWrapper
                                            ref={transformComponentRef}
                                            doubleClick={{ mode: isZoomedIn ? 'zoomOut' : 'zoomIn', step: 1 }} // Double-click to zoom in
                                            wheel={{ disabled: true }} // Disable zoom on wheel scroll
                                        >
                                            <TransformComponent>
                                                <img
                                                    src={`${process.env.REACT_APP_ROOTURL}/${m?.path}`}
                                                    alt="Document"
                                                    className="full-screen-image"
                                                />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    </div>
                                ))}
                            </div>
                        }
                        <div className='page-data-container'>
                            <div className="content-display">
                                {currentSection === 'home' && (
                                    <>
                                        <Menu onClick={handleMenuClick} selectedKeys={[currentMenu]} mode="horizontal" items={items} />
                                        <div className="content-item-display">
                                            {currentMenu === 'content' && recordSection === 'file' &&
                                                <div>
                                                    <div onClick={() => handleCopyText(selectedPage?.markdown)} style={{ textAlign: 'end', cursor: 'pointer' }}>
                                                        {contextHolder}
                                                        <CopyTwoTone twoToneColor="#8C2BE4" />
                                                    </div>
                                                    <div className="react-markdown">
                                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                            {selectedPage?.markdown}
                                                        </ReactMarkdown>
                                                    </div>
                                                </div>
                                            }{currentMenu === 'content' && recordSection === 'record' &&
                                                <div style={{ overflowX: 'hidden' }}>
                                                    {contextHolder}

                                                    <div
                                                        onClick={() => handleCopyText(
                                                            selectedRecord?.FilePages?.map(m => `Page: ${m.pageNumber} \n ${m.markdown}`).join('\n\n')
                                                        )}
                                                        style={{ textAlign: 'end', cursor: 'pointer' }}
                                                    >
                                                        <CopyTwoTone twoToneColor="#8C2BE4" />
                                                    </div>
                                                    {selectedRecord?.FilePages?.map((m, index) => (
                                                        <div key={m.id}>
                                                            <h2 style={{
                                                                textAlign: 'left',
                                                                borderTop: index !== 0 ? '1px solid black' : 'none' // Apply border only if it's not the first item
                                                            }}>
                                                                Page: {m.pageNumber}
                                                            </h2>
                                                            <div className="react-markdown">
                                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                                    {m?.markdown}
                                                                </ReactMarkdown>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            }
                                            {currentMenu === 'task' && recordSection === 'record' &&
                                                <div style={{ overflowX: 'hidden' }}>
                                                    <Collapse defaultActiveKey={['1']}>
                                                        {selectedRecord?.TaskResult?.map(m => (
                                                            <Panel header={m?.Task?.name} key={m?.Task?.id}>
                                                                <div style={{ overflowX: 'auto' }}>
                                                                    <div onClick={() => handleCopyText(m?.result)} style={{ textAlign: 'end', cursor: 'pointer' }}>
                                                                        {contextHolder}
                                                                        <CopyTwoTone twoToneColor="#8C2BE4" />
                                                                    </div>
                                                                    <div className="react-markdown">
                                                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                                            {m?.result}
                                                                        </ReactMarkdown>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        ))}
                                                    </Collapse>
                                                </div>
                                            }
                                            {currentMenu === 'summary' && recordSection === 'file' &&
                                                <div>
                                                    <div onClick={() => handleCopyText(selectedPage?.markdown)} style={{ textAlign: 'end', cursor: 'pointer' }}>
                                                        {contextHolder}
                                                        <CopyTwoTone twoToneColor="#8C2BE4" />
                                                    </div>
                                                    <p style={{ textAlign: 'left', margin: '10px' }}>
                                                        {selectedPage?.DomainData[0]?.summary}
                                                    </p>
                                                </div>
                                            }{currentMenu === 'summary' && recordSection === 'record' &&
                                                <div style={{ overflowX: 'hidden' }}>
                                                    {contextHolder}
                                                    <div
                                                        onClick={() => handleCopyText(
                                                            selectedRecord?.FilePages?.map(m => `Page: ${m.pageNumber} \n ${m?.DomainData[0]?.summary}`).join('\n\n')
                                                        )}
                                                        style={{ textAlign: 'end', cursor: 'pointer' }}
                                                    >
                                                        <CopyTwoTone twoToneColor="#8C2BE4" />
                                                    </div>
                                                    {selectedRecord?.FilePages?.map((m, index) => (
                                                        <div key={m.id}>
                                                            <h2 style={{
                                                                textAlign: 'left',
                                                                borderTop: index !== 0 ? '1px solid black' : 'none' // Apply border only if it's not the first item
                                                            }}>
                                                                Page: {m.pageNumber}
                                                            </h2>
                                                            <div>
                                                                <p style={{ textAlign: 'left', margin: '10px' }}>
                                                                    {m?.DomainData[0]?.summary}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </>
                                )}
                                {currentSection === 'chat' &&
                                    <div className='chat-container'>
                                        <div className='chat-data' ref={chatContainerRef}>
                                            {messages.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className={`message-bubble ${message.role === 'user' ? 'user-message' : 'received-message'}`}
                                                >
                                                    <div className="react-markdown-message">
                                                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                                            {message.content}
                                                        </ReactMarkdown>
                                                    </div>
                                                </div>
                                            ))}
                                            {loading && (  // Display loading wave inside assistant message bubble
                                                <div className="message-bubble loading-message">
                                                    <div className="loading-dots">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='chat-input-container'>
                                            <textarea
                                                ref={textareaRef}
                                                className='chat-input'
                                                placeholder='Type your message...'
                                                value={inputValue}
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                                rows={1}
                                            />
                                            <SendOutlined
                                                className='send-icon'
                                                onClick={() => handleSend()}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="bottom-menu">
                                <Menu onClick={handleSectionClick} selectedKeys={[currentSection]} mode="horizontal" items={homeItems} />
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Files;
