import { DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, message, Modal, Checkbox, Spin, Form, Upload, Steps } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import '../App.css';


const { Step } = Steps;
const { Dragger } = Upload;

const CustomTable = () => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [messageApi, contextHolder] = message.useMessage();
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [file, setFile] = useState(null);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const limit = 5;
    const navigate = useNavigate();

    useEffect(() => {
        getFiles();
        fetchTasks();
        // eslint-disable-next-line
    }, [currentPage]);

    const getFiles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PORTURL}/file-upload?page=${currentPage}&limit=${limit}`);
            setData(response.data.files);
            setTotal(response.data.total);
        } catch (error) {
            console.error('Error fetching files:', error);
            message.error('Failed to fetch files.');
        }
    };

    const fetchTasks = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PORTURL}/task/all`);
            setTasks(response.data);

        } catch (error) {
            console.error('Error fetching tasks:', error);
            message.error('Failed to fetch tasks.');
        }
    };

    const success = (content) => {
        messageApi.open({
            type: 'success',
            content,
            duration: 5,
        });
    };

    const showUploadModal = () => {
        setCurrentStep(0);
        setFile(null);
        setSelectedTasks([]);
        setIsUploadModalVisible(true);
    };

    const handleStepChange = (current) => {
        setCurrentStep(current);
    };

    const handleFileChange = (info) => {
        setFile(info.file);
    };

    const handleStep1Next = () => {
        if (file) {
            handleStepChange(1);
        } else {
            message.error('Please select a file.');
        }
    };

    const handleStep2Finish = async () => {
        if (selectedTasks.length === 0) {
            message.error('Please select at least one task.');
            return;
        }

        if (!file) {
            message.error('No file selected.');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('taskIds', JSON.stringify(selectedTasks));

        try {
            await axios.post(`${process.env.REACT_APP_PORTURL}/file-upload/upload`, formData);
            success('File uploaded successfully.');
            getFiles();
            setIsUploadModalVisible(false);
        } catch (error) {
            console.error('Error uploading file:', error);
            message.error('Error uploading file.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRecord = async (record) => {
        try {
            await axios.delete(`${process.env.REACT_APP_PORTURL}/file-upload/${record.id}`);
            success('File deleted successfully.');
            getFiles();
        } catch (error) {
            console.error('Error deleting record:', error);
            message.error('Error deleting record.');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'orginalFileName',
            key: 'name',
        },
        {
            title: 'Upload Time',
            dataIndex: 'createdAt',
            key: 'uploadTime',
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('MMM DD, YYYY HH:mm:ss')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Completed', value: 'COMPLETED' },
                { text: 'In Progress', value: 'IN PROGRESS' },
            ],
            onFilter: (value, record) => record.status.includes(value),
        },
        {
            title: 'Progress',
            dataIndex: 'completedPercentage',
            key: 'progress',
            sorter: (a, b) => a.completedPercentage.replace('%', '') - b.completedPercentage.replace('%', ''),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Completed Time',
            dataIndex: 'completedTime',
            key: 'completedTime',
            sorter: (a, b) => new Date(a.completedTime) - new Date(b.completedTime),
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('MMM DD, YYYY HH:mm:ss')
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <span>
                    <Button
                        type="link"
                        disabled={record.status === "EXTRACTING_PAGES"}
                        icon={<EyeOutlined />}
                        onClick={() => navigate(`files/${record.id}`)}
                    />
                    <Popconfirm
                        title="Delete the file"
                        description="Are you sure to delete this file?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteRecord(record)}
                    >
                        <Button type="link" icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div style={{ padding: '20px', width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {contextHolder}

            <Button
                type="primary"
                style={{ marginBottom: '20px', width: 'fit-content', alignSelf: 'end' }}
                onClick={showUploadModal}
            >
                Upload File
            </Button>

            <Table
                className='custom-table'
                columns={columns}
                dataSource={data}
                rowKey="id"
                onChange={(pagination) => {
                    if (pagination) {
                        setCurrentPage(pagination.current);
                    }
                }}
                pagination={{
                    total,
                    pageSize: limit,
                    showSizeChanger: false,
                }}
                style={{ backgroundColor: '#fff', borderRadius: '8px' }}
            />

            <Modal
                title="Upload File"
                visible={isUploadModalVisible}
                onCancel={() => setIsUploadModalVisible(false)}
                footer={null}
                width={600}
                centered
                bodyStyle={{ padding: '20px' }}
                style={{ borderRadius: '8px' }}
            >
                {loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Spin tip="Uploading..." />
                    </div>
                ) : (
                    <>
                        <Steps current={currentStep} onChange={handleStepChange} style={{ marginBottom: '20px' }}>
                            <Step title="Select File" />
                            <Step title="Select Tasks" />
                        </Steps>
                        {currentStep === 0 && (
                            <Form layout="vertical">
                                <Form.Item label="Select File" required>
                                    <Dragger
                                        name="file"
                                        multiple={false}
                                        beforeUpload={() => false} // Prevent auto-upload
                                        onChange={(e) => handleFileChange(e)}
                                        showUploadList={false} // Hide the upload list
                                        accept=".pdf,.xlsx,.xls"
                                        style={{
                                            border: '2px dashed #d9d9d9',
                                            borderRadius: '8px',
                                            padding: '20px',
                                            backgroundColor: '#fafafa',
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <UploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">Drag & drop a file here, or click to select a file</p>
                                    </Dragger>
                                </Form.Item>
                                {file && (
                                    <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                                        <strong>Selected File:</strong> {file.name}
                                    </div>
                                )}
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={handleStep1Next}
                                        disabled={!file}
                                        style={{ marginTop: '20px' }}
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                        {currentStep === 1 && (
                            <Form layout="vertical">
                                <Form.Item label="Select Tasks" required>
                                    <div style={{ height: "200px", overflowY: 'auto', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
                                            <Checkbox.Group
                                                className="custom-checkbox"  // Add custom class here
                                                style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}
                                                options={tasks.map(task => ({ label: task.name, value: task.id }))}
                                                value={selectedTasks}
                                                onChange={setSelectedTasks}
                                            />
                                        </div>
                                    </div>

                                </Form.Item>
                                <Form.Item>
                                    <Button type="default" onClick={() => handleStepChange(0)}>
                                        Back
                                    </Button>
                                    <Button type="primary" onClick={handleStep2Finish} style={{ marginLeft: '10px' }}>
                                        Finish
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </>
                )}
            </Modal>
        </div>
    );
};

export default CustomTable;
