import { LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../assest/Logo black.svg'; // Adjust the path if necessary
import './Home.css';
import CustomTable from './Table';
import Files from './Files';
import Task from './Task';

const { Header, Content } = Layout;

function Home() {

  const navigate = useNavigate()

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f0f0f0', padding: '0 16px' }}>
        {/* Logo on the left */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Logo style={{ width: '120px', height: '50px', marginRight: '20px' }} />
        </div>

        <div style={{display:'flex',gap:'100px',padding:'20px'}}>
          {/* Navigation Menu */}
          <Menu
            mode="horizontal"
            defaultSelectedKeys={['page']}
            style={{
              backgroundColor: 'transparent',
              borderBottom: 'none',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center'
            }}
            className="custom-menu"
          >
            <Menu.Item onClick={() => navigate('')} key="page" className="menu-item">
              Files
            </Menu.Item>
            <Menu.Item onClick={() => navigate('task')} key="task" className="menu-item">
              Task
            </Menu.Item>
          </Menu>
          {/* Logout icon on the right */}
          <LogoutOutlined style={{ fontSize: '20px', color: '#FF4D4F', marginLeft: 'auto' }} />
        </div>
      </Header>

      <Content style={{ height: '100%', background: 'white' }}>
        <Routes>
          <Route path='' element={<CustomTable />}></Route>
          <Route path='files/:id' element={<Files />}></Route>
          <Route path='task' element={<Task />}></Route>
        </Routes>
      </Content>
    </Layout>
  );
}

export default Home;
