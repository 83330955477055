import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, message, Modal, Form, Input } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import "./Task.css";

const Task = () => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 5;
    // eslint-disable-next-line 
    const [messageApi, contextHolder] = message.useMessage();
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [currentTask, setCurrentTask] = useState({name:"",description:"",prompt:""});
    const [form] = Form.useForm();
    const [viewForm] = Form.useForm();
    const [editForm] = Form.useForm();

    useEffect(() => {
        getFiles();
    }, [currentPage]);

    const showCreateModal = () => {
        setIsCreateModalVisible(true);
    };

    const handleCreateOk = async () => {
        form.validateFields()
            .then(values => {
                axios.post(`${process.env.REACT_APP_PORTURL}/task`, values)
                    .then(() => {
                        getFiles();
                    })
                    .catch((error) => {
                        console.error('Error creating task:', error);
                    });
                setIsCreateModalVisible(false);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const handleCreateCancel = () => {
        setIsCreateModalVisible(false);
    };

    const showViewModal = (task) => {
        setCurrentTask(task);
        viewForm.setFieldsValue(task); // Populate the form with task data
        setIsViewModalVisible(true);
    };

    const handleViewCancel = () => {
        setIsViewModalVisible(false);
    };

    const showEditModal = (task) => {
        setCurrentTask(task);
        editForm.setFieldsValue(task); // Populate the form with task data
        setIsEditModalVisible(true);
    };

    const handleEditOk = () => {
        editForm.validateFields()
            .then(values => {
                axios.patch(`${process.env.REACT_APP_PORTURL}/task/${currentTask.id}`, values)
                    .then(() => {
                        getFiles();
                    })
                    .catch((error) => {
                        console.error('Error updating task:', error);
                    });
                setIsEditModalVisible(false);
                setIsViewModalVisible(false);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
        setIsViewModalVisible(false);
    };

    const getFiles = async () => {
        const response = await axios.get(`${process.env.REACT_APP_PORTURL}/task`);
        setData(response.data.tasks);
        setTotal(response.data.total);
    };

    const deleteRecord = (record) => {
        axios.delete(`${process.env.REACT_APP_PORTURL}/task/${record}`)
            .then(() => {
                getFiles();
            })
            .catch((error) => {
                console.error('Error deleting record:', error);
            });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (text) => (
                <span>{text.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
            ),
        },
        {
            title: 'Prompt',
            dataIndex: 'prompt',
            render: (text) => (
                <span>{text.length > 30 ? `${text.substring(0, 30)}...` : text}</span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
                <span>
                    <Button onClick={() => showViewModal(record)} type="link" icon={<EyeOutlined />} />
                    <Button onClick={() => showEditModal(record)} type="link" icon={<EditOutlined />} />
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteRecord(record.id)}
                    >
                        <Button type="link" icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div style={{ padding: '20px 40px 40px 40px', width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
            {contextHolder}

            <Button
                type="primary"
                style={{ marginBottom: '20px', width: 'fit-content', alignSelf: 'end' }}
                onClick={showCreateModal}
            >
                Create Task
            </Button>

            <Table
                className='custom-table'
                columns={columns}
                dataSource={data}
                rowKey="id"
                onChange={(pagination, filters, sorter) => {
                    if (pagination) {
                        setCurrentPage(pagination.current);
                    }
                }}
                pagination={{
                    total,
                    pageSize: limit,
                }}
            />

            <CSSTransition
                in={isCreateModalVisible}
                timeout={300}
                classNames="modal-custom"
                unmountOnExit
            >
                <div className="custom-modal-wrapper">
                    <Modal
                        title="Task Creation"
                        visible={isCreateModalVisible}
                        onOk={handleCreateOk}
                        onCancel={handleCreateCancel}
                        okText="Create"
                        cancelText="Cancel"
                        footer={null}
                        bodyStyle={{ padding: 0 }}
                        className="custom-modal-content"
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            name="task_form"
                        >
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please input the task name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ required: true, message: 'Please input the task description!' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                name="prompt"
                                label="Prompt"
                                rules={[{ required: true, message: 'Please input the task prompt!' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleCreateOk} style={{ marginRight: '8px' }}>
                                    Create
                                </Button>
                                <Button onClick={handleCreateCancel}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </CSSTransition>

            <CSSTransition
                in={isViewModalVisible}
                timeout={300}
                classNames="modal-custom"
                unmountOnExit
            >
                <div className="custom-modal-wrapper">
                    <Modal
                        title="Task Details"
                        visible={isViewModalVisible}
                        onCancel={handleViewCancel}
                        footer={null}
                        className="custom-modal-content"
                    >
                        <Form
                            form={viewForm}
                            layout="vertical"
                            name="view_task_form"
                            initialValues={currentTask}
                        >
                           <p><strong>Task Name:</strong> {currentTask.name} </p>
                           <p><strong>Task Description:</strong> {currentTask.description} </p>
                           <p><strong>Task Prompt:</strong> {currentTask.prompt} </p>
                           <Form.Item style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={() => showEditModal(currentTask)} style={{ marginRight: '8px' }}>
                                    Edit
                                </Button>
                                <Button onClick={handleViewCancel}>Close</Button>
                            </Form.Item>
                      </Form>
                    </Modal>
                </div>
            </CSSTransition>

            <CSSTransition
                in={isEditModalVisible}
                timeout={300}
                classNames="modal-custom"
                unmountOnExit
            >
                <div className="custom-modal-wrapper">
                    <Modal
                        title="Edit Task"
                        visible={isEditModalVisible}
                        onOk={handleEditOk}
                        onCancel={handleEditCancel}
                        okText="Save"
                        cancelText="Cancel"
                        footer={null}
                        bodyStyle={{ padding: 0 }}
                        className="custom-modal-content"
                    >
                        <Form
                            form={editForm}
                            layout="vertical"
                            name="edit_task_form"
                            initialValues={currentTask}
                        >
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please input the task name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ required: true, message: 'Please input the task description!' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                name="prompt"
                                label="Prompt"
                                rules={[{ required: true, message: 'Please input the task prompt!' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button type="primary" onClick={handleEditOk} style={{ marginRight: '8px' }}>
                                    Save
                                </Button>
                                <Button onClick={handleEditCancel}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </CSSTransition>
        </div>
    );
};

export default Task;
